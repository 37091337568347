import {
	CommentParentObject,
	CustomTab,
	LocalizedSb,
	News,
	Post,
	SeoMultiLang,
	Sex,
	Sport,
	SportId,
	StatCareer,
	StatMatch as StatMatchV2,
	StatPlayer,
	StatPlayerCareer,
	StatPlayer as StatPlayerV2,
	StatSeason,
	StatTeam,
	StatTournament,
	StatVenue,
	StatWindowTransfers,
	Tag,
	TagObjectValue,
	TagOtherExtra,
	TagPersonExtra,
	TagPersonRoleId,
	TagSeoPage,
	TagSeoPageType,
	TagTeamExtra,
	TagTournamentExtra,
	TagType as TagTypeV2,
	TransferWindow,
} from '@ui-kit/main-api-types';
import {
	BOOKIE_DEF_RATING_URLS,
	CASINO_DEF_RATING_URLS,
} from '@ui-kit/main-frontend-ui-kit/dist/src/utils';
import {
	BY_TRIBUNA_COM,
	TRIBUNA_COM,
	UA_TRIBUNA_COM,
} from '@ui-kit/main-frontend-ui-kit/dist/src/utils/constants';
import dayjs from '@ui-kit/main-frontend-ui-kit/dist/src/utils/dayjs';
import { parseStructuredBody } from '@ui-kit/main-frontend-ui-kit/dist/src/utils/helpers';
import {
	DropdownItem,
	TeamMemberRole,
	LANG as KIT_LANG,
} from '@ui-kit/main-frontend-ui-kit/dist/src/utils/types';
import { StatInOut, StatTransferSort } from '@ui-kit/main-int-api-types';
import {
	LANG as STAT_LANG,
	TeamSqudTabs,
} from '@ui-kit/statistics-ui-kit/dist/src/utils/types';
import {
	GetServerSideProps,
	GetServerSidePropsContext,
	GetServerSidePropsResult,
} from 'next';
import { useTranslations } from 'next-intl';

import {
	MatchStatus,
	ReactionType,
	StatLineupLine,
	StatMatch,
	StatSourceList,
	TagNewFull,
	TagSeoFull,
	TagSeoPageFull,
	TagTeamExtraFull,
	TagTeamType,
	TagType,
	UserRole,
} from '@generated-graphql';
import { locales } from '@utils/navigations_intl';
import { isMobileDeviceMDNCheck } from '@utils/tools';

import { BETTING_SPORT_URLS } from './bookie';
import {
	ONE_DAY,
	ONE_HOUR,
	TABS,
	TRIBUNA_COM_URL,
	TWITTER_USERNAME,
	TWO_DAYS,
} from './constants';
import crossFetch from './fetch';
import {
	AvatarType,
	ContentTypeName,
	DayjsWithAddFunction,
	LANG,
	Tab,
	TagDirectories,
	TagSubTypesUrls,
	TagTabPath,
	TagTabTypes,
	TagTypesUrls,
	TeamType,
} from './types';

const ids = {};
const hrus = {};

interface ICacheForHubsArgs {
	hru?: string;
	isMatchEnded: boolean;
	scheduledAt?: string;
	season?: StatSeason;
}
export function cacheForHubs({
	hru = '',
	isMatchEnded,
	scheduledAt = '',
	season = null,
}: ICacheForHubsArgs): string {
	const currentDate = new Date();
	const matchDate = new Date(scheduledAt).getTime();
	const nowDate = Date.now();

	if (hru) {
		if (isMatchEnded) {
			if (+season?.year < currentDate.getFullYear()) {
				return 'max-age=43200';
			}
			if (matchDate > nowDate - TWO_DAYS) {
				return 'max-age=20';
			}
			return 'max-age=3600';
		} else {
			if (matchDate - nowDate > ONE_DAY) {
				return 'max-age=3600';
			} else {
				if (matchDate - nowDate > ONE_HOUR) {
					return 'max-age=900';
				} else {
					return 'max-age=20';
				}
			}
		}
	}
}

interface IComputeGraceForMatches {
	scheduledAt: string;
}

export const computeGraceForMatches = ({
	scheduledAt,
}: IComputeGraceForMatches) => {
	const matchDate = new Date(scheduledAt).getTime();
	const nowDate = Date.now();

	const timeToMatch = nowDate - matchDate;

	if (0 < timeToMatch && timeToMatch < TWO_DAYS) {
		return 'no';
	}

	return '';
};

export const getServerSidePropsHOF =
	(context: GetServerSidePropsContext) => async (f: GetServerSideProps) => {
		const localePre =
			(context.query.locale as string) || (context.query.lang as string);
		const locale = localePre?.toLowerCase();
		if (!locale || !['en', 'fr', 'de', 'es', 'it', 'ar'].includes(locale)) {
			return Promise.resolve({ notFound: true }) as Promise<
				GetServerSidePropsResult<{
					notFound: boolean;
				}>
			>;
		}
		context.locale = locale;
		try {
			return await f({
				...context,
				locale,
				locales: locales,
				defaultLocale: 'en',
			});
		} catch (error) {
			if ((error as Error)?.message?.search(/NOT_FOUND/gi) > -1) {
				return Promise.resolve({ notFound: true }) as Promise<
					GetServerSidePropsResult<{
						notFound: boolean;
					}>
				>;
			} else {
				throw error;
			}
		}
	};

export const convertTagTypeToAvatarType = (type: TagType): AvatarType => {
	switch (type) {
		case TagType.Person:
			return AvatarType.Person;
		case TagType.Tournament:
			return AvatarType.Tournament;
		case TagType.Team:
			return AvatarType.Team;
		default:
			AvatarType.Other;
	}
};

export const getTagLogo = (tag: TagNewFull | Tag): string =>
	tag?.logo?.url || tag?.logo?.main || '';

export const getPostStructuredElement = (
	element: LocalizedSb,
	locale: string,
) => {
	if (element?.[locale]?.length) {
		return element?.[locale];
	}
	return [];
};

export const clubs = {
	acmilan: {
		id: '1046353',
		hru: 'milan',
		name: 'Milan',
		langs: ['it'],
		old_id: 'ac_milan',
	},
	fcbarcelona: {
		id: '1363867',
		hru: 'barcelona',
		name: 'Barcelona',
		langs: ['en', 'es', 'fr', 'ar'],
		old_id: 'barcelona',
	},
	manutd: {
		id: '1046599',
		hru: 'manchester-united',
		name: 'Manchester United',
		langs: ['en'],
		old_id: 'manchester_united',
	},
	liverpoolfc: {
		id: '1046732',
		hru: 'liverpool',
		name: 'Liverpool',
		langs: ['en'],
		old_id: 'liverpool',
	},
	realmadrid: {
		id: '1363868',
		hru: 'real-madrid',
		name: 'Real Madrid',
		langs: ['en', 'es', 'fr', 'ar'],
		old_id: 'real_madrid',
	},
	chelsea: {
		id: '1046674',
		hru: 'chelsea',
		name: 'Chelsea',
		langs: ['en'],
		old_id: 'chelsea',
	},
	arsenal: {
		id: '1685202',
		hru: 'arsenal',
		name: 'Arsenal',
		langs: ['en'],
		old_id: 'arsenal',
	},
	fcbayern: {
		id: '1044515',
		hru: 'bayern-munchen',
		name: 'FC Bayern',
		langs: ['de'],
		old_id: 'bayern_munich',
	},
	inter: {
		id: '1046351',
		hru: 'inter',
		name: 'Internazionale',
		langs: ['it'],
		old_id: 'inter_milan',
	},
	juventus: {
		id: '1048490',
		hru: 'juventus',
		name: 'Juventus',
		langs: ['it'],
		old_id: 'juventus_turin',
	},
	psg: {
		id: '1044570',
		hru: 'psg',
		name: 'PSG',
		langs: ['fr'],
		old_id: 'paris_saint_germain',
	},
	dortmund: {
		id: '1044519',
		hru: 'borussia-dortmund',
		name: 'Borussia Dortmund',
		langs: ['de'],
		old_id: '1044519',
		img: 'https://pictures.ua.tribuna.com/image/218f000e-e738-4c63-8404-698b0f0b1293?width=120&quality=70',
	},
};

export const computeCacheForMatches = ({
	hru,
	id,
	status,
	scheduledAt = '',
	season = null,
}) => {
	const isMatchEnded = status === 'cancelled' || status === 'closed';
	if (hru) {
		const isCacheAvailable = cacheForHubs({
			hru,
			isMatchEnded,
			scheduledAt,
			season,
		});

		return isCacheAvailable || '';
	}

	if (!hru && id && isMatchEnded) {
		return 'max-age=86400';
	}
};

export const isItMatchHru = id => /-vs-/g.test(id);

export const sourceForMatch = matchId =>
	isItMatchHru(matchId) ? StatSourceList.TribunaHub : StatSourceList.Global;

export const getUserCountryCode = req => {
	if (req.headers) {
		return req.headers['x-geoip2-city-country-code'] || '';
	}

	return null;
};

export const isExternalLinkToBlog = link => {
	if (!link) {
		return false;
	}

	const reg = /https?:\/\/tribuna\.com\/.*\/blogs\/.*/g;
	return reg.test(link);
};

export const isTribunaImage = src => {
	const reg = /\.*(tribuna|trbna|s5o)\.(com|ru)\/*/g;
	return reg.test(src);
};

export const clubsForLang = lang => {
	const allClubs = Object.keys(clubs);
	const forThisLang = allClubs.filter(club =>
		clubs[club].langs ? clubs[club].langs.indexOf(lang) > -1 : false,
	);
	return forThisLang;
};

export const clubsWithValuesForLang = lang => {
	const allClubs = Object.keys(clubs);
	const forThisLang = allClubs
		.filter(club =>
			clubs[club].langs ? clubs[club].langs.indexOf(lang) > -1 : false,
		)
		.map(club => ({ ...clubs[club], alias: club }));
	return forThisLang;
};

Object.keys(clubs).forEach(key => {
	ids[clubs[key].id] = {
		name: key,
		langs: clubs[key].langs,
		hru: clubs[key].hru,
	};
	hrus[clubs[key].hru] = {
		name: key,
		langs: clubs[key].langs,
		hru: clubs[key].hru,
	};
});

export const langsForClubs = club => {
	const forThisLang =
		(clubs[club] || hrus[club] || ids[club] || {}).langs || [];
	return forThisLang;
};
export const getAppnameFromNewsHru = ({ newsHru = '', fakeTop = false }) => {
	let result = '';
	const indexOfSplitter = newsHru && newsHru.indexOf('-');
	const valueInString =
		indexOfSplitter < 0 ? newsHru : newsHru?.substring(0, indexOfSplitter);
	const appNameFromHru =
		valueInString?.match(
			/^[0-9]*([a-zA-Z]+|[a-zA-Z]+[0-9]+|[0-9]+[a-zA-Z]+)/g,
		) || [];
	if (fakeTop) {
		appNameFromHru.length > 0 &&
			fakeTopsList.indexOf(valueInString) > -1 &&
			(result = valueInString);
	} else {
		appNameFromHru.length > 0 &&
			apps.indexOf(valueInString) > -1 &&
			(result = valueInString);
	}

	return result;
};

export const projects = {
	transfers: {
		id: 'transfers',
		name: 'Transfers',
		langs: ['en'],
	},
};
export const langs = ['en', 'it', 'es', 'fr', 'de', 'ar'];
export const testLangs = ['it', 'es', 'fr', 'de', 'ar'];
export const mainTag = 'championsleague';
export const fakeTopsList = [
	'borussia-dortmund',
	'napoli',
	'marseille',
	'mancity',
	'asroma',
];

export const apps = [...Object.keys(clubs), ...Object.keys(projects), mainTag];

/**
 *
 * @param id
 * @returns {*}
 */
export function getName(id) {
	if (ids[id]) {
		return ids[id].name;
	} else if (hrus[id]) {
		return hrus[id].name;
	}
	return null;
}

/**
 *
 * @param {string} alias
 * @returns {string}
 */
export function getId(alias) {
	return clubs[alias] ? clubs[alias].id : alias;
}
/**
 *
 * @param {string} alias
 * @returns {string}
 */
export function getHru(alias) {
	return clubs[alias] ? clubs[alias].hru : alias;
}
export function getHruByID(id) {
	if (ids[id]) {
		return ids[id].hru;
	} else if (hrus[id]) {
		return hrus[id].hru;
	}
	return undefined;
}

export const trimSymbol = (str: string, symbol: string): string => {
	let newStr = str;

	if (str?.startsWith(symbol)) {
		newStr = str.slice(1);
	}

	if (str.endsWith(symbol)) {
		newStr = newStr.slice(0, -1);
	}

	return newStr;
};

export function isEditorialClub({ alias = '', id = '', lang }) {
	if (alias && clubs[alias] && clubs[alias].langs.indexOf(lang) > -1) {
		return true;
	}
	return !!(
		id &&
		getName(id) &&
		clubs[getName(id)] &&
		clubs[getName(id)].langs.indexOf(lang) > -1
	);
}

export function isEditorialProject({ alias, lang }) {
	return alias && projects[alias] && projects[alias].langs.indexOf(lang) > -1;
}

export function isEditorial({ alias, id, lang }) {
	return (
		isEditorialProject({ alias, lang }) || isEditorialClub({ alias, id, lang })
	);
}

/**
 * Проверяем является ли val hru
 * @param {string} val
 * @returns {boolean}
 */
export function isHru(val) {
	const datePattern = new RegExp(
		/\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])-*/gm,
	);
	return datePattern.test(val);
}

/**
 * Актуальная ссылка на различные типы страниц
 * @param {string} [alias]
 * @param {string} [id]
 * @param {string} pageType
 * @returns {string}
 */
export function getLinkForTypes({ alias, id, pageType = '' }) {
	let link = '';
	if (alias) {
		link = `${getId(alias)}/`;
	} else if (id) {
		const name = getName(id);
		if (name) {
			if (clubs[name]) {
				link = `${name}/`;
			}
		}
		link = `clubs/${id}/`;
	}

	return `${link}${pageType}/`;
}

export const generalChatRooms = {
	championsleague: {
		id: '4401',
	},
	fcbarcelona: {
		id: '1211',
	},
	realmadrid: {
		id: '4361',
	},
	manutd: {
		id: '4354',
	},
	chelsea: {
		id: '4398',
	},
	arsenal: {
		id: '4403',
	},
	liverpoolfc: {
		id: '4397',
	},
	juventus: {
		id: '4356',
	},
	acmilan: {
		id: '4402',
	},
	inter: {
		id: '4362',
	},
	psg: {
		id: '4409',
	},
	fcbayern: {
		id: '4369',
	},
	transfers: {
		id: 'premierleague',
	},
};

export const oldNotation = new RegExp(
	`/(${apps.join('|')})/(${langs.join('|')})/`,
);
export const newNotation = new RegExp(
	`/(${langs.join('|')})/(${apps.join('|')})/.*`,
);
export const mainTagOldNotaion = new RegExp(
	`/(${mainTag})/(${langs.join('|')})/`,
);
export const appnamesInUrl = new RegExp(`/(${apps.join('|')})/`);

export const getTopTransfers = ({ currentLocale = LANG.en }) => {
	const allTransfers = {
		EPL: {
			name: 'EPL',
			img: 'https://pictures.tribuna.com/image/5d675201-2184-457e-a03e-a603ab202cef?width=1920&quality=70',
			link: `/${currentLocale}/league/epl/transfers/`,
			type: 'league',
			id: 'epl_tournament',
		},
		'Primera Division': {
			name: 'Primera Division',
			img: 'https://pictures.ua.tribuna.com/image/091071aa-0c02-46a1-a3da-75c547677433?width=1920&quality=70',
			link: `/${currentLocale}/league/la-liga/transfers/`,
			type: 'league',
			id: 'la_liga_tournament',
		},
		'Serie A': {
			name: 'Serie A',
			img: 'https://pictures.ua.tribuna.com/image/b7698a55-9ca0-4d21-a2e3-79eab2596504?width=1920&quality=70',
			link: `/${currentLocale}/league/serie-a/transfers/`,
			type: 'league',
			id: 'serie_a_tournament',
		},
		Bundesliga: {
			name: 'Bundesliga',
			img: 'https://pictures.ua.tribuna.com/image/88b4c149-143b-43f1-9f32-f06730d15ff9?width=1920&quality=70',
			link: `/${currentLocale}/league/bundesliga/transfers/`,
			type: 'league',
			id: 'bundesliga_tournament',
		},
		'Ligue 1': {
			name: 'Ligue 1',
			img: 'https://pictures.ua.tribuna.com/image/2ce9c771-ee9c-4fe6-8a93-e33cf0d77909?width=1920&quality=70',
			link: `/${currentLocale}/league/ligue-1/transfers/`,
			type: 'league',
			id: 'ligue_1_tournament',
		},
		'Manchester United': {
			name: 'Manchester United',
			img: 'https://pictures.ua.tribuna.com/image/62c107dd-be80-4a39-8e2d-49fdc1cbbc6a?width=1920&quality=70',
			link: `/${currentLocale}/clubs/manchester-united/transfers/`,
			type: 'clubs',
			id: 'manchester_united_team',
		},
		Chelsea: {
			name: 'Chelsea',
			img: 'https://pictures.ua.tribuna.com/image/14ea655d-258f-41e3-bfab-e10a2c9d6e5d?width=1920&quality=70',
			link: `/${currentLocale}/clubs/chelsea/transfers/`,
			type: 'clubs',
			id: 'chelsea_team',
		},
		Liverpool: {
			name: 'Liverpool',
			img: 'https://pictures.ua.tribuna.com/image/408ddc70-11e5-4241-8185-1e67107616f4?width=1920&quality=70',
			link: `/${currentLocale}/clubs/liverpool/transfers/`,
			type: 'clubs',
			id: 'liverpool_team',
		},
		Arsenal: {
			name: 'Arsenal',
			img: 'https://pictures.ua.tribuna.com/image/e0d59bd2-db1f-4b4c-b038-a2bb16ee3463?width=1920&quality=70',
			link: `/${currentLocale}/clubs/arsenal/transfers/`,
			type: 'clubs',
			id: 'arsenal_team',
		},
		Barcelona: {
			name: 'Barcelona',
			img: 'https://pictures.ua.tribuna.com/image/2fbccebb-e367-42fa-b10a-28b1430c5256?width=1920&quality=70',
			link: `/${currentLocale}/clubs/barcelona/transfers/`,
			type: 'clubs',
			id: 'barcelona_team',
		},
		'Real Madrid': {
			name: 'Real Madrid',
			img: 'https://pictures.ua.tribuna.com/image/987c5f96-31c6-40fa-ab30-f469fb4ea3c6?width=1920&quality=70',
			link: `/${currentLocale}/clubs/real-madrid/transfers/`,
			type: 'clubs',
			id: 'real_madrid_team',
		},
		Milan: {
			name: 'Milan',
			img: 'https://pictures.ua.tribuna.com/image/f7f4e141-4a8f-4246-b068-9311927038b6?width=1920&quality=70',
			link: `/${currentLocale}/clubs/milan/transfers/`,
			type: 'clubs',
			id: 'ac_milan_team',
		},
		Inter: {
			name: 'Inter',
			img: 'https://pictures.ua.tribuna.com/image/37b14087-0baf-4da2-bed4-c0a9508cba1f?width=1920&quality=70',
			link: `/${currentLocale}/clubs/inter/transfers/`,
			type: 'clubs',
			id: 'inter_milan_team',
		},
		Juventus: {
			name: 'Juventus',
			img: 'https://pictures.ua.tribuna.com/image/c07128cc-dbdb-4efb-945d-c7a7545738c3?width=1920&quality=70',
			link: `/${currentLocale}/clubs/juventus/transfers/`,
			type: 'clubs',
			id: 'juventus_turin_team',
		},
		'FC Bayern': {
			name: 'FC Bayern',
			img: 'https://pictures.ua.tribuna.com/image/f150ba58-44f9-4c40-abd1-e4fdf07be93b?width=1920&quality=70',
			link: `/${currentLocale}/clubs/bayern-munchen/transfers/`,
			type: 'clubs',
			id: 'bayern_munich_team',
		},
		PSG: {
			name: 'PSG',
			img: 'https://pictures.ua.tribuna.com/image/a1b94361-5c4e-4776-ab88-f6579458eea3?width=1920&quality=70',
			link: `/${currentLocale}/clubs/psg/transfers/`,
			type: 'clubs',
			id: 'paris_saint_germain_team',
		},
		'Borussia Dortmund': {
			name: 'Borussia Dortmund',
			img: 'https://pictures.ua.tribuna.com/image/218f000e-e738-4c63-8404-698b0f0b1293?width=1920&quality=70',
			link: `/${currentLocale}/clubs/borussia-dortmund/transfers/`,
			type: 'clubs',
			id: 'borussia_dortmund_team',
		},
	};

	const transfersByLang = {
		en: [
			'EPL',
			'Primera Division',
			'Serie A',
			'Bundesliga',
			'Ligue 1',
			'Manchester United',
			'Chelsea',
			'Liverpool',
			'Arsenal',
			'Barcelona',
			'Real Madrid',
		],
		fr: [
			'Ligue 1',
			'EPL',
			'Primera Division',
			'Serie A',
			'Bundesliga',
			'PSG',
			'Barcelona',
			'Real Madrid',
		],
		de: [
			'Bundesliga',
			'EPL',
			'Primera Division',
			'Serie A',
			'Ligue 1',
			'FC Bayern',
			'Borussia Dortmund',
			'Barcelona',
			'Real Madrid',
		],
		it: [
			'Serie A',
			'EPL',
			'Primera Division',
			'Bundesliga',
			'Ligue 1',
			'Milan',
			'Inter',
			'Juventus',
		],
		es: [
			'Primera Division',
			'EPL',
			'Serie A',
			'Bundesliga',
			'Ligue 1',
			'Barcelona',
			'Real Madrid',
		],
		ar: [
			'EPL',
			'Primera Division',
			'Serie A',
			'Bundesliga',
			'Ligue 1',
			'Manchester United',
			'Chelsea',
			'Liverpool',
			'Arsenal',
			'Barcelona',
			'Real Madrid',
		],
	};

	return transfersByLang[currentLocale]?.map(tag => allTransfers[tag]);
};

export const topLeaguesForLang = ({ lang = 'en' }) => {
	const allLeagues = {
		// en
		Championship: {
			text: 'Championship',
			id: 'championship',
			img: 'https://stat-pictures.tribuna.com/image/379931e9-5640-4bfb-b2c4-7576545c267c',
			link: `${TRIBUNA_COM_URL}${lang}/league/championship/`,
			type: 'league',
		},
		'FA Cup': {
			text: 'FA Cup',
			id: 'fa-cup-eng',
			img: 'https://pictures.ua.tribuna.com/image/4129ea2e-afdb-4850-8833-d0dd1a620dbd?width=120&quality=70',
			link: `${TRIBUNA_COM_URL}${lang}/league/english-cup/`,
			type: 'league',
		},

		// it
		'Serie B': {
			text: 'Serie B',
			id: 'serie-b',
			img: 'https://pictures.tribuna.com/image/d51a6579-3023-4fab-a70a-ef2af40c29e4',
			link: `${TRIBUNA_COM_URL}${lang}/league/serie-b/`,
			type: 'league',
		},
		'Coppa Italia': {
			text: 'Coppa Italia',
			id: 'coppa-italia',
			img: 'https://pictures.tribuna.com/image/fe5d3c93-bd86-4f82-8923-bb11aa167b68',
			link: `${TRIBUNA_COM_URL}${lang}/league/coppa-italia/`,
			type: 'league',
		},

		// es
		'Segunda Division': {
			text: 'Segunda Division',
			id: 'la-liga-2',
			img: 'https://pictures.tribuna.com/image/90598d5b-ac61-4c83-ae4f-ba6aa0a187e7',
			link: `${TRIBUNA_COM_URL}${lang}/league/segunda/`,
			type: 'league',
		},
		'Copa del Rey': {
			text: 'Copa del Rey',
			id: 'copa-del-rey',
			img: 'https://pictures.trbna.com/image/ab191ab3-e144-4e01-b5de-85ebf1abf4aa',
			link: `${TRIBUNA_COM_URL}${lang}/league/copa-del-rey/`,
			type: 'league',
		},

		// fr
		'Ligue 2': {
			text: 'Ligue 2',
			id: 'ligue-2',
			img: 'https://pictures.tribuna.com/image/31cc6f11-1864-4a00-8465-14699bef1035',
			link: `${TRIBUNA_COM_URL}${lang}/league/ligue-2/`,
			type: 'league',
		},
		'Coupe de France': {
			text: 'Coupe de France',
			id: 'coupe-de-france',
			img: 'https://pictures.trbna.com/image/d3606846-877b-4356-9c92-a104901f951b',
			link: `${TRIBUNA_COM_URL}${lang}/league/coupe-de-france/`,
			type: 'league',
		},

		// de
		'Bundesliga 2': {
			text: 'Bundesliga 2',
			id: '2nd-bundesliga',
			img: 'https://pictures.tribuna.com/image/94ef80d1-32eb-4055-a757-afe6c419d18b',
			link: `${TRIBUNA_COM_URL}${lang}/league/2-bundesliga/`,
			type: 'league',
		},
		'DFB Pokal': {
			text: 'DFB Pokal',
			id: 'dfb-pokal',
			img: 'https://pictures.trbna.com/image/454303ed-7861-4b54-81e5-a2c1758b5616',
			link: `${TRIBUNA_COM_URL}${lang}/league/dfb-pokal/`,
			type: 'league',
		},

		'FIFA World Cup 2022': {
			text: 'FIFA World Cup 2022',
			id: 'world-cup',
			translations: {
				en: 'FIFA World Cup 2022',
				it: 'Coppa del Mondo FIFA 2022',
				fr: 'Coupe du monde de la FIFA 2022',
				es: 'Copa Mundial de la FIFA 2022',
				de: 'FIFA Fussball-Weltmeisterschaft 2022',
			},
			img: 'https://pictures.tribuna.com/image/2deb845a-9d7a-4fb8-90dd-09cc05e124b2',
			link: `${TRIBUNA_COM_URL}${lang}/league/world-cup/`,
			type: 'league',
		},

		'UEFA Champions League': {
			text: 'UEFA Champions League',
			id: 'uefa-champions-league',
			img: 'https://stat-pictures.tribuna.com/image/b228f463-e9d6-4313-b2a8-0efe6497dc40',
			link: `${TRIBUNA_COM_URL}${lang}/league/ucl/`,
			type: 'league',
		},
		'UEFA Europa League': {
			text: 'UEFA Europa League',
			id: 'europa-league',
			img: 'https://stat-pictures.tribuna.com/image/89f8eafe-15a1-4c9a-aeaf-edf83cba5f4a',
			link: `${TRIBUNA_COM_URL}${lang}/league/europa-league/`,
			type: 'league',
		},
		'Premier League': {
			text: 'Premier League',
			id: 'epl',
			img: 'https://stat-pictures.tribuna.com/image/0f3a2364-13e2-4bea-b10e-79e134ae19dd',
			link: `${TRIBUNA_COM_URL}${lang}/league/epl/`,
			type: 'league',
		},
		'La Liga': {
			text: 'Primera División',
			id: 'la-liga',
			img: 'https://stat-pictures.tribuna.com/image/31b8e9bc-e362-4b29-b930-0f0c0e9dee40',
			link: `${TRIBUNA_COM_URL}${lang}/league/la-liga/`,
			type: 'league',
		},
		'Serie A': {
			text: 'Serie A',
			id: 'seria-a',
			img: 'https://stat-pictures.tribuna.com/image/017cf3f8-2cc4-4c07-af49-f816c61d8bfa',
			link: `${TRIBUNA_COM_URL}${lang}/league/serie-a/`,
			type: 'league',
		},
		Bundesliga: {
			text: 'Bundesliga',
			id: 'bundesliga',
			img: 'https://stat-pictures.tribuna.com/image/bf1da489-126b-4b34-83b9-3a8f25d93942',
			link: `${TRIBUNA_COM_URL}${lang}/league/bundesliga/`,
			type: 'league',
		},
		'Ligue 1': {
			text: 'Ligue 1',
			id: 'ligue-1',
			img: 'https://stat-pictures.tribuna.com/image/317ffa85-ee1a-4abb-90d7-4804547f504a',
			link: `${TRIBUNA_COM_URL}${lang}/league/ligue-1/`,
			type: 'league',
		},
	};

	const langToLeaguesList = {
		en: [
			'Premier League',
			'UEFA Champions League',
			'UEFA Europa League',
			'Championship',
			'FA Cup',
			'La Liga',
			'Serie A',
			'Bundesliga',
			'Ligue 1',
		],
		it: [
			'Serie A',
			'UEFA Champions League',
			'UEFA Europa League',
			'Serie B',
			'Coppa Italia',
			'Premier League',
			'La Liga',
			'Bundesliga',
			'Ligue 1',
		],
		es: [
			'La Liga',
			'UEFA Champions League',
			'UEFA Europa League',
			'Segunda Division',
			'Copa del Rey',
			'Premier League',
			'Serie A',
			'Bundesliga',
			'Ligue 1',
		],
		fr: [
			'Ligue 1',
			'UEFA Champions League',
			'UEFA Europa League',
			'Ligue 2',
			'Coupe de France',
			'Premier League',
			'La Liga',
			'Serie A',
			'Bundesliga',
		],
		de: [
			'Bundesliga',
			'UEFA Champions League',
			'UEFA Europa League',
			'Bundesliga 2',
			'DFB Pokal',
			'Premier League',
			'La Liga',
			'Serie A',
			'Ligue 1',
		],
		ar: [
			'UEFA Champions League',
			'UEFA Europa League',
			'Premier League',
			'La Liga',
			'Serie A',
			'Bundesliga',
			'Ligue 1',
		],
	};

	const leaguesForLang = [];
	if (langToLeaguesList[lang]) {
		langToLeaguesList[lang].forEach(el => {
			const currentLeague = allLeagues[el] || null;
			currentLeague &&
				leaguesForLang.push({
					...currentLeague,
					name: currentLeague.text || '',
					image:
						currentLeague.img ||
						'https://pictures.tribuna.com/image/e9e1e975-556b-4f8f-8370-c57147c2c77b',
					img:
						currentLeague.img ||
						'https://pictures.tribuna.com/image/e9e1e975-556b-4f8f-8370-c57147c2c77b',
				});
		});
	} else {
		return langToLeaguesList['en'];
	}

	return leaguesForLang;
};

export function getChatRoomLink({ appname, id, lang }) {
	if (appname === 'transfers' || appname === 'championsleague') {
		return '';
	}
	const chatLink =
		generalChatRooms[appname] &&
		generalChatRooms[appname].id &&
		generalChatRooms[appname].id === id
			? `/${lang}/${appname}/chat/`
			: `/${lang}/${appname}/chat-${id}/`;

	return chatLink;
}

/**
 * Имя персоны
 * @param {object} person
 * @returns {string}
 */
export function getPersonName(person) {
	const firstName = person?.firstName;
	const lastName = person?.lastName;
	return firstName && lastName
		? `${firstName} ${lastName}`
		: person?.coalesceName;
}

/**
 * Конвертирует таймштамп в нужный для SEO вид
 * @returns {string}
 */
export const convertDateForSeo = ({ stamp }) => {
	return new Date(stamp)
		.toLocaleString('en-US', { timeZoneName: 'short' })
		.split(',')[0];
};

/**
 * Возвращает число голов, забитых игроком
 * @param {object} stat
 * @returns {number}
 */
export const getStatGoalsCount = stat => {
	let count = 0;
	if (stat?.goalsScored) {
		count += stat.goalsScored;
	}
	if (stat?.ownGoals && !stat?.goalsScored) {
		count += stat.ownGoals;
	}
	return count;
};

export const getFirstLastName = (firstName, lastName) => {
	let name;
	if (firstName && lastName && firstName !== lastName) {
		return `${firstName} ${lastName}`;
	} else if (lastName) {
		return lastName;
	} else if (firstName) {
		return firstName;
	}
	return name;
};

export const getFullName = (tag: TagNewFull | Tag, lang: string) => {
	const player = tag?.statObject as StatPlayer;
	const { firstName, lastName, name } = player || {};

	return firstName && lastName
		? `${firstName} ${lastName}`
		: name || tag?.title?.[lang];
};

const partition = (predicate, list) =>
	list.reduce(
		([resolved, rejected], item, index) => {
			predicate(item, index) ? resolved.push(item) : rejected.push(item);

			return [resolved, rejected];
		},
		[[], []],
	);
/**
 * WARNING!!! Function should return max 3 tags
 */
export const getTagsForMeta = (content: News | Post, locale: string) => {
	const seoTags = [];

	const newsTags = content?.tags;

	const [tournamentTags, otherTags] = partition(
		tag => tag?.type === TagType.Tournament,
		newsTags,
	);

	const isTournamentTagAvailable = tournamentTags?.length > 0;
	if (isTournamentTagAvailable) {
		seoTags.push(tournamentTags?.[0]?.title?.[locale]);
	}

	const restTagsAmount = isTournamentTagAvailable ? 2 : 3;
	otherTags
		?.slice(0, restTagsAmount)
		.forEach(tag => seoTags.push(tag?.title?.[locale]));

	return seoTags;
};
const EMPTY_VALUE = 'EMPTY_VALUE';

export const sendAnalytics = ({
	category,
	name,
	value = '',
	label = '',
	contentName = '',
}) => {
	// if (!(window as any)?.dataLayer) {
	// 	(window as any).dataLayer = [];
	// }

	return (window as any)?.dataLayer?.push(
		contentName !== ''
			? {
					event: 'custom_event',
					'data-event-category': category || EMPTY_VALUE,
					'data-event-name': name || EMPTY_VALUE,
					'data-event-value': value || EMPTY_VALUE,
					'data-event-label': label || EMPTY_VALUE,
					'content-name': contentName,
				}
			: {
					event: 'custom_event',
					'data-event-category': category || EMPTY_VALUE,
					'data-event-name': name || EMPTY_VALUE,
					'data-event-value': value || EMPTY_VALUE,
					'data-event-label': label || EMPTY_VALUE,
				},
	);
};

export const sendPageInformation = ({
	pageId,
	pageType,
	pageTags,
	pageSection = '',
}): { window: Window } => {
	if (!(window as any)?.dataLayer) {
		(window as any).dataLayer = [];
	}

	return (window as any)?.dataLayer?.push({
		event: 'page_data',
		page_id: pageId || '',
		page_type: pageType || '',
		page_tags: pageTags || '',
		page_section: pageSection || '',
	});
};

export const isWrongPage = page => !page?.match(/^page[1-9]\d*$/);

export const isWrongYearAndSeason = (param?: string): boolean =>
	param ? !param?.match(/^\d{4}-(summer|winter)/) : true;

export const getTagLink = path => {
	const isItNewsOrBlogsPage =
		path?.split('/')?.includes('news') || path?.split('/')?.includes('blogs');
	const pathArray = path?.split('/');
	console.info({ pathArray });
	let tagMainLink;
	if (pathArray?.length < 6) {
		tagMainLink = pathArray?.slice(0, 3).join('/');
	}

	let link;
	if (isItNewsOrBlogsPage) {
		link = `${tagMainLink}/`;
	} else {
		link = path;
	}
	return link;
};

export const preventXss = (str: string): string => {
	return str
		?.replace(/&/g, '&amp;')
		?.replace(/</g, '&lt;')
		?.replace(/>/g, '&gt;')
		?.replace(/"/g, '&quot;')
		?.replace(/'/g, '&#039;')
		?.replace(/<script.*?>|<\/script>/gi, '');
};

//Typescript starts

export const getTagTabs = (
	tagTypes: TagSeoPageType[] = [],
	t: any,
	tabs: Tab[],
	customTabs?: CustomTab[],
): Tab[] => {
	const formattedCustomTabs = customTabs?.map(tab => ({
		id: tab.id,
		title: tab.name,
		link: tab?.redirect || tab?.hru,
	}));
	return [
		...[TagTabTypes.Main, ...tagTypes].reduce((acc, tagType) => {
			const tab = tabs.find(item => item.id === tagType);
			if (tab && Object.keys(tab)?.length) {
				acc.push({ ...tab, title: t(`tags.${tab.title}`) });
			}
			return acc;
		}, []),
		...(formattedCustomTabs || []),
	];
};

export const getInitialTab = (tabType: TagTabTypes, tabs: Tab[]): Tab => {
	return tabs.find(tab => tab.id === tabType) || TABS[0];
};

export const getMatchLink = (
	match: StatMatch | StatMatchV2,
	localeLink: string,
) => `${TRIBUNA_COM_URL}${localeLink}/match/${match?.hru || match?.id}/`;

export const countDifferenceBetweenDates = (
	date: Date | string,
): {
	isToday: boolean;
	isYesterday: boolean;
	isPreviousCalendarYears: boolean;
	daysDifference: number;
	isMoreThanOneYear: boolean;
} => {
	const formattedDate = dayjs(date).format('YYYY-MM-DD');
	const formattedYear = dayjs(date).format('YYYY');
	const dateNow = dayjs().format('YYYY-MM-DD');

	const daysDifference = dayjs(dateNow).diff(formattedDate, 'days', true);
	const monthsDifference = dayjs(dateNow).diff(formattedDate, 'month', true);
	const yearsDifference = dayjs(dateNow).diff(formattedYear, 'years');

	return {
		isToday: daysDifference === 0,
		isYesterday: daysDifference === 1,
		isPreviousCalendarYears: yearsDifference > 0,
		daysDifference,
		isMoreThanOneYear: monthsDifference > 12,
	};
};

export const getMatchStatusCaption = (
	status: MatchStatus,
	beginningTime = '',
): string => {
	const t = useTranslations('translations');

	switch (status) {
		case MatchStatus.Live:
			return `${t('match-live')}`;
		case MatchStatus.Ended:
		case MatchStatus.Closed:
			return `${t('match-ended')}`;
		case MatchStatus.NotStarted:
			return beginningTime;
		case MatchStatus.Postponed:
			return `${t('postponed')}`;
		default:
			return `${t('cancelled')}`;
	}
};

export const tranformLeaguesToDropdownItems = (leagues, locale) => {
	return leagues.reduce(
		(acc, curr) => [
			...acc,
			{ id: curr.id, text: curr?.translations?.[locale] || curr?.text },
		],
		[],
	);
};

interface IGetLinkArgs {
	alias?: string;
	id?: string;
	type?: TagTeamType;
	lang?: LANG;
}

export function getLink({ alias, id, lang, type }: IGetLinkArgs) {
	const teamType = type === TagTeamType.National ? 'team' : 'clubs';

	if (alias) {
		return `/${lang}/${teamType}/${getHruByID(getId(alias)) || alias}/`;
	}
	if (id) {
		const hru = getHruByID(id);
		return `/${lang}/${teamType}/${hru || id}/`;
	}
}

export const getStartingLineup = (
	fullLineup?: StatLineupLine[],
): StatLineupLine[] =>
	fullLineup?.filter(({ lineupStarting }) => lineupStarting) || [];

export const getSubstitutions = (
	fullLineup?: StatLineupLine[],
): StatLineupLine[] =>
	fullLineup?.filter(({ lineupStarting }) => !lineupStarting) || [];

export const getLinkShareViaTwitter = (url: string, title: string): string =>
	`https://twitter.com/intent/tweet?text=${title}&url=${url}&via=${TWITTER_USERNAME}`;

export const getLinkShareViaFacebook = (url: string): string =>
	`https://www.facebook.com/sharer/sharer.php?u=${url}`;

export const computeReactionCount = (
	oldReaction: ReactionType,
	newReaction: ReactionType,
	count: number,
): number => {
	if (newReaction === ReactionType.Like && oldReaction === ReactionType.Like) {
		return count - 1;
	} else if (
		newReaction === ReactionType.Like &&
		oldReaction === ReactionType.None
	) {
		return count + 1;
	} else if (
		newReaction === ReactionType.Like &&
		oldReaction === ReactionType.Dislike
	) {
		return count + 2;
	} else if (
		newReaction === ReactionType.Dislike &&
		oldReaction === ReactionType.Dislike
	) {
		return count + 1;
	} else if (
		newReaction === ReactionType.Dislike &&
		oldReaction === ReactionType.None
	) {
		return count - 1;
	} else if (
		newReaction === ReactionType.Dislike &&
		oldReaction === ReactionType.Like
	) {
		return count - 2;
	}
	return count;
};

export const addSourceLink = (e, moreOn) => {
	const selection = window.getSelection();
	const text = `${selection}\n\n${moreOn} Tribuna.com:\n${location.href}?utm_source=copy`;
	e.clipboardData.setData('text/plain', text);
	e.preventDefault();
};

export const getTagDirectoryByType = (
	type: TagType | TagTypeV2,
	isNationalTeam?: boolean,
): TagDirectories => {
	switch (type) {
		case TagType.Team:
			return isNationalTeam ? TagDirectories.Team : TagDirectories.Clubs;
		case TagType.Tournament:
			return TagDirectories.League;
		case TagType.Person:
			return TagDirectories.Persons;
		case TagType.Other:
			return TagDirectories.Tags;
		default:
			return null;
	}
};
export const getTagRootLink = (
	data: (TagNewFull | Tag) & { locale: string },
): string => {
	const { hru, id, type, locale, extra } = data;
	const typePath = getTagDirectoryByType(
		type,
		(extra as TagTeamExtraFull)?.type === TagTeamType.National,
	);
	return `/${locale}/${typePath ? `${typePath}/` : ''}${hru || id}/`;
};

export function getPersonLastName(
	person: StatPlayerV2,
	locale: string,
): string {
	const lastName = (person?.tag?.extra as TagPersonExtra)?.lastName?.[locale];

	return lastName || person?.tag?.title?.[locale] || person?.coalesceName;
}

export const hasModeratorRoles = (
	userRoles: UserRole[],
	moderatorRoles: string[],
): boolean => !!userRoles?.find(role => moderatorRoles.includes(role?.ID));

export const addIfObj = <T>(condition: boolean, props: T) =>
	condition ? props : ({} as T);

export const addIfArr = <T>(condition: boolean, props: T): T | undefined[] =>
	condition ? props : [];

export const isHTMLElement = (el: unknown): el is HTMLElement => {
	return (el as HTMLElement)?.addEventListener !== undefined;
};

export default {
	getId,
	getHru,
	getHruByID,
	getName,
	trimSymbol,
	langs,
	testLangs,
	isEditorialClub,
	topLeaguesForLang,
	getLink,
	getLinkForTypes,
	clubs,
	projects,
	mainTag,
	apps,
	oldNotation,
	newNotation,
	mainTagOldNotaion,
	clubsForLang,
	isHru,
	sourceForMatch,
	computeCacheForMatches,
	isItMatchHru,
	generalChatRooms,
	getPersonName,
	convertDateForSeo,
	getStatGoalsCount,
	isTribunaImage,
	getLinkShareViaTwitter,
	getLinkShareViaFacebook,
	computeReactionCount,
};

export const scrollToElement = (tab: Tab) => {
	const element = document.getElementById(tab?.id as string);
	const y = element?.getBoundingClientRect().top + window.pageYOffset - 200;
	window.scrollTo({ top: y, behavior: 'smooth' });
};

export const getCoutryCode = () => {
	if (window.location.origin.indexOf('localhost') > -1) {
		return 'DE';
	}

	const url =
		window.location.origin.indexOf('localhost') > -1
			? 'https://stage.tribuna.com'
			: window.location.origin?.replace(/(it|fr|de|es|ar)\./, '');

	return crossFetch(`${url}/current-geo`, {
		method: 'GET',
	})
		.then((res = {}) => {
			return res['geo-ip-country-code'];
		})
		.catch(error => {
			return Promise.reject(error);
		});
};

export const translateContentType = (
	contentType: TagTabPath.News | TagTabPath.Blogs,
	t: any,
) => {
	switch (contentType) {
		case TagTabPath.News:
			return t('translations.news');
		case TagTabPath.Blogs:
			return t('translations.blogs');
	}
};

export const getDefaultTitle = (
	contentType: TagTabPath.News | TagTabPath.Blogs,
	name: string,
	t: any,
) => {
	switch (contentType) {
		case TagTabPath.News:
			return t('tags.news-page-title', { name });
		case TagTabPath.Blogs:
			return t('tags.blogs-page-title', { name });
		default:
			return t('tags.main-page-title', { name });
	}
};

export const getDefaultDescription = (
	contentType: TagTabPath.News | TagTabPath.Blogs,
	name: string,
	t: any,
) => {
	switch (contentType) {
		case TagTabPath.News:
			return t('tags.news-page-description', { name });
		case TagTabPath.Blogs:
			return t('tags.blogs-page-description', { name });
		default:
			return t('tags.main-page-description', { name });
	}
};

export const getContentType = (
	type: string,
): TagTabPath.News | TagTabPath.Blogs => {
	switch (type) {
		case 'news':
			return TagTabPath.News;
		case 'blogs':
			return TagTabPath.Blogs;
		default:
			return null;
	}
};
export const getPlayerTagWithStat = ({
	tag,
	playerStat,
}: {
	tag: Tag;
	playerStat?: StatPlayerCareer[];
}): Tag => ({
	...tag,
	statObject: {
		...tag?.statObject,
		...(playerStat && { stat: [...playerStat] }),
	} as StatPlayer,
});

export const generateLinkForClub = ({
	id,
	hru,
	lang,
	type = TagTeamType.Club,
}) =>
	`/${lang}/${type === TagTeamType.National ? 'team' : 'clubs'}/${hru || id}/`;

export const isPersonCoach = (type: TeamMemberRole) =>
	[TeamMemberRole.Coach, TeamMemberRole.AssistantCoach].includes(type);

export const getPersonRoleLabel = (type: TeamMemberRole) => {
	const t = useTranslations();
	const isCoach = isPersonCoach(type);
	return isCoach ? t('translations.coach') : t('translations.players');
};
export const getTeamSquadTabs = (tagLink, t: any): Tab[] => [
	{
		id: TeamSqudTabs.List,
		title: t('tags.list'),
		link: `${tagLink}squad/`,
	},
	{
		id: TeamSqudTabs.Stat,
		title: t('tags.stat'),
		link: `${tagLink}squad/stat/`,
	},
	{
		id: TeamSqudTabs.TheBest,
		title: t(`tags.${TeamSqudTabs.TheBest}`),
		link: `${tagLink}squad/best/`,
	},
];

export const getTransferWindowName = (
	year: number | string,
	seasonName: StatWindowTransfers,
	lang: LANG,
	t: any,
) => {
	const lowerSeasonName = seasonName?.toLowerCase();
	if (lang === LANG.fr) {
		return `${t(`translations.${lowerSeasonName}`)} - ${year}`;
	}
	return `${year} - ${t(`translations.${lowerSeasonName}`)}`;
};

export const getTransfersList = (
	transfers: TransferWindow[],
	basicRoute: string,
	lang: LANG,
	t: any,
): DropdownItem[] =>
	transfers?.map(({ year, window: currentWindow }) => {
		const text = getTransferWindowName(year, currentWindow, lang, t);
		return {
			text,
			code: `${year} ${currentWindow.toUpperCase()}`,
			link: `${basicRoute}${year}-${currentWindow.toLowerCase()}/`,
		};
	});

export const doesExistTransferWindow = (
	currentYear: number,
	currentWindow: StatWindowTransfers,
	transferWindows: TransferWindow[],
): boolean =>
	!!transferWindows?.find(
		({ year, window }) => currentYear === year && currentWindow === window,
	);

export const getSeoInfo = (
	tag?: TagNewFull | Tag,
	pageType?: TagSeoPageType,
): TagSeoFull | SeoMultiLang =>
	(tag?.seoPages as (TagSeoPageFull | TagSeoPage)[])?.find(
		page => page?.type === pageType,
	)?.seo;

export const getTheBestPlayersWidgetData = ({
	tagData,
	tagType,
	tag,
	t,
}: {
	tagData?: Tag;
	tagType?: TagTypeV2;
	tag?: TagObjectValue;
	t?: any;
}): {
	theBestPlayers?: StatSeason[];
	linkToAllStat?: string;
	title: string;
} => {
	let theBestPlayers: StatSeason[];
	let linkToAllStat: string;
	let title: string;

	if (tagType === TagTypeV2.Tournament) {
		//TODO: вернуть, когда появится вкладка стату
		// linkToAllStat = tabs?.includes(TagTabTypes.Stats) ? 'stat/' : '';
		linkToAllStat = '';
		theBestPlayers = [(tag as StatTournament)?.currentSeason as StatSeason];
		const seasonName = (tag as StatTournament)?.currentSeason?.shortName;
		title = t('tags.the-best-in-season', {
			season: seasonName,
		});
	} else if (tagType === TagTypeV2.Team) {
		linkToAllStat = tagData?.tabs?.includes(TagTabTypes.Teams)
			? 'squad/stat/'
			: '';
		theBestPlayers = [
			(tag as StatTeam)?.homeTournament?.currentSeason as StatSeason,
		];
		const seasonName = (tag as StatTeam)?.homeTournament?.name;
		title = t('tags.the-best-in-team-in-season', {
			season: seasonName,
		});
	}
	return { theBestPlayers, linkToAllStat, title };
};

export const getTeamTypeFromUrl = (tagTypeUrl: string): TagTeamType => {
	if (tagTypeUrl === TagTypesUrls.NationalTeam) {
		return TagTeamType.National;
	}
	if (tagTypeUrl === TagTypesUrls.Clubs) {
		return TagTeamType.Club;
	}
	return null;
};

export const getTagsDropdownTypeItems = ({
	sport,
	locale,
}: {
	sport: Sport;
	locale?: string;
}): DropdownItem[] => {
	const t = useTranslations('translations');
	const isBetting = sport?.id === SportId.Bet;

	const basicLink = sport
		? `/${locale}/${isBetting ? BETTING_SPORT_URLS?.[locale] : sport?.path}/`
		: `/${locale}/`;

	return [
		{
			text: t('all-tags'),
			id: TagTypesUrls.AllTags,
			link: `${basicLink}${TagTypesUrls.AllTags}/`,
		},
		{
			text: t('teams'),
			id: TagTypesUrls.Clubs,
			link: `${basicLink}${TagTypesUrls.Clubs}/`,
		},
		{
			text: t('national-teams'),
			id: TagTypesUrls.NationalTeam,
			link: `${basicLink}${TagTypesUrls.NationalTeam}/`,
		},
		{
			text: t('tournaments'),
			id: TagTypesUrls.League,
			link: `${basicLink}${TagTypesUrls.League}/`,
		},
		{
			text: t('persons'),
			id: TagTypesUrls.Persons,
			link: `${basicLink}${TagTypesUrls.Persons}/`,
		},
		{
			text: t('players'),
			id: TagSubTypesUrls.Athlete,
			link: `${basicLink}${TagSubTypesUrls.Athlete}/`,
		},
		{
			text: t('coach'),
			id: TagSubTypesUrls.Coach,
			link: `${basicLink}${TagSubTypesUrls.Coach}/`,
		},
		{
			text: t('referees'),
			id: TagSubTypesUrls.Referee,
			link: `${basicLink}${TagSubTypesUrls.Referee}/`,
		},
		{
			text: t('managers'),
			id: TagSubTypesUrls.Manager,
			link: `${basicLink}${TagSubTypesUrls.Manager}/`,
		},
		{
			text: t('journalist'),
			id: TagSubTypesUrls.Journalist,
			link: `${basicLink}${TagSubTypesUrls.Journalist}/`,
		},
		{
			text: t('others'),
			id: TagTypesUrls.Other,
			link: `${basicLink}${TagTypesUrls.Other}/`,
		},
		{
			text: t('bookmakers'),
			id: 'bookies',
			link: `/${locale}/${BETTING_SPORT_URLS?.[locale]}/${BOOKIE_DEF_RATING_URLS?.[locale]}/`,
		},
		{
			text: t('casino'),
			id: 'casinos',
			link: `/${locale}/casino/${CASINO_DEF_RATING_URLS?.[locale]}/`,
		},
	];
};

/*
Explanation for params:
 @index - index of content element
 @startingElementIndex - index of the element after which the advertisement should be
 @interval - interval between next advertisement after startingNumber
*/

export const shouldShowAdvertisement = ({
	index,
	startingElementIndex,
	interval,
}: {
	index: number;
	startingElementIndex: number;
	interval: number;
}): boolean =>
	index === startingElementIndex ||
	(index > startingElementIndex &&
		(index - startingElementIndex) % interval === 0);

export const shouldShowAdvertisementForNewsDependsOfDevice = ({
	index,
	startingElementIndex,
}: {
	index: number;
	startingElementIndex: number;
}): boolean =>
	index === startingElementIndex || isMobileDeviceMDNCheck()
		? shouldShowAdvertisement({
				index,
				startingElementIndex,
				interval: 10,
			})
		: shouldShowAdvertisement({
				index,
				startingElementIndex,
				interval: 15,
			});

export const shouldShowAdvertisementForPostsDependsOfDevice = ({
	index,
	startingElementIndex,
	desktopAdvertisementInterval = 10,
	mobileAdvertisementInterval = 5,
}: {
	index: number;
	startingElementIndex: number;
	desktopAdvertisementInterval?: number;
	mobileAdvertisementInterval?: number;
}) =>
	index === startingElementIndex || isMobileDeviceMDNCheck()
		? shouldShowAdvertisement({
				index,
				startingElementIndex,
				interval: mobileAdvertisementInterval,
			})
		: shouldShowAdvertisement({
				index,
				startingElementIndex,
				interval: desktopAdvertisementInterval,
			});

export const getFaqItemsAndSeoText = async (
	tagSeo: SeoMultiLang,
	locale: LANG | STAT_LANG,
) => {
	const { structure: seoText = [] } = await parseStructuredBody({
		structuredBody: tagSeo?.text?.defaultValue || tagSeo?.text?.[locale],
	});
	const filteredFaqItems =
		tagSeo?.faqItems?.filter(item => !!item?.title?.[locale]) || [];

	return {
		...tagSeo,
		seoText,
		faqItems: filteredFaqItems,
	};
};

export const changeUrlWithoutRefreshPage = (newUrl: string) => {
	history.pushState({}, null, newUrl);
};

export const getNewsTagsList = (tags: Tag[], locale: string) =>
	tags?.map(({ statObject, type, hru, id, title, extra, logo }) => {
		let tagLogo: string = logo?.url;
		if ([TagTypeV2.Tournament, TagTypeV2.Team].includes(type) && !tagLogo) {
			tagLogo = statObject?.['logo']?.webp;
		}
		if (type === TagTypeV2.Person && !tagLogo) {
			tagLogo = (statObject as StatPlayerV2)?.avatar?.webp;
		}
		const directory = getTagDirectoryByType(
			type,
			(extra as TagTeamExtra)?.type === TagTeamType.National,
		);
		const link = `/${locale}/${directory ? `${directory}/` : ''}${hru || id}/`;
		return {
			logo: tagLogo,
			text: title?.[locale] || statObject?.name,
			link,
		};
	});
export const getSeasonTournamentSubPageName = (
	seasonName?: string,
	tournamentName?: string,
) => {
	if (seasonName && tournamentName) {
		return '_season_tournament';
	}

	return seasonName ? '_season' : '';
};

export const getTournamentFixturesSubPageName = (
	seasonName?: string,
	tour?: string,
) => {
	if (seasonName && tour) {
		return '_season_tour';
	}

	return seasonName ? '_season' : '';
};

export const computeCacheForMatchCenter = (
	date: DayjsWithAddFunction,
	isLive: boolean,
): string => {
	if (isLive || date.isToday()) {
		return 'max-age=60';
	}

	return 'max-age=1800';
};

interface ICommentContentInfo {
	locale: string;
	locales: string[];
	content: CommentParentObject;
}

export const commentContentInfo = ({
	content,
	locale,
	locales,
}: ICommentContentInfo) => {
	let usageLocale = '';
	if (
		content?.title?.[locale] ||
		content?.['newsTitle']?.[locale] ||
		content?.structuredBody?.[locale]
	) {
		usageLocale = locale;
	} else if (
		content?.title?.en ||
		content?.['newsTitle']?.en ||
		content?.structuredBody?.en
	) {
		usageLocale = LANG.en;
	} else {
		usageLocale = locales?.find(
			lang =>
				content?.title?.[lang] ||
				content?.['newsTitle']?.[lang] ||
				content?.structuredBody?.[lang],
		);
	}

	const contentTitle =
		content?.title?.[usageLocale] ||
		content?.['newsTitle']?.[usageLocale] ||
		content?.structuredBody?.[usageLocale]?.[0]?.value?.elements?.[0]?.value
			?.text;

	const directory =
		content?.['__typename'] === ContentTypeName.News ? 'news' : 'blogs';

	const contentLink =
		content && !!contentTitle
			? `/${usageLocale}/${directory}/${content?.hru || content?.id}/`
			: '';

	const isContentAvailable =
		content?.structuredBody?.[usageLocale]?.[0]?.value?.elements?.length > 0;

	return { contentTitle, contentLink, isContentAvailable };
};

export function reverseArray(arr) {
	return arr.slice().reverse();
}

export const getCurrentTeamHomeTournamentId = (career: StatCareer[]): string =>
	career?.find(
		teamData => !!teamData?.active && teamData?.team?.type === TeamType.CLUB,
	)?.team?.homeTournament?.id;

export const getPersonRole = (tag: Tag, locale: string, t: any) => {
	const tagExtra = tag?.extra as TagPersonExtra;
	const sex = tagExtra?.sex;
	const gender = sex === Sex.Female ? 'f' : 'm';
	const rolesMap = {
		[TagPersonRoleId.Unknown]: t('tags.person'),
		[TagPersonRoleId.Athlete]: tag?.sport?.athleteTitle?.[gender]?.[locale],
	};
	const role = rolesMap[tagExtra?.role?.id] || tagExtra?.role?.name?.[locale];
	return role?.[0].toUpperCase() + role?.slice(1);
};

type TeamInfo = {
	teamTypeUrl: string;
	teamTypeName: string;
};

export const getTeamInfo = ({ tag }: { tag?: Tag }): TeamInfo => {
	const { statObject, extra } = tag || {};
	const teamType =
		((statObject as StatTeam)?.type as TeamType) ||
		TeamType[(extra as TagTeamExtra)?.type as TagTeamType];
	const isNationalTeam = teamType === TeamType.NATIONAL;
	return {
		teamTypeUrl: isNationalTeam ? 'team' : 'clubs',
		teamTypeName: isNationalTeam ? 'national' : 'clubs',
	};
};

export const isInstEmbedExist = (
	articles: (string | Record<string, any>)[],
) => {
	return articles.some(content => {
		if (typeof content === 'object') {
			return content?.value?.id;
		}
	});
};

export const getYear = () => new Date().getFullYear();

export const getInitialTransfersFilters = () => ({
	year: new Date().getFullYear(),
	window:
		new Date().getMonth() > 4 && new Date().getMonth() < 11
			? StatWindowTransfers.Summer
			: StatWindowTransfers.Winter,
	transfersType: StatInOut.All,
	sort:
		dayjs().isBetween(`${dayjs().year()}-06-01`, `${dayjs().year()}-09-11`) ||
		dayjs().isBetween(`${dayjs().year()}-01-01`, `${dayjs().year()}-02-11`)
			? StatTransferSort.DescDate
			: StatTransferSort.DescPrice,
});

export const getISO3Country = ({ locale }: { locale: KIT_LANG }) => {
	const localeToCountry = {
		[KIT_LANG.en]: 'GBR', // United Kingdom
		[KIT_LANG.de]: 'DEU', // Germany
		[KIT_LANG.es]: 'ESP', // Spain
		[KIT_LANG.it]: 'ITA', // Italy
		[KIT_LANG.fr]: 'FRA', // France
		[KIT_LANG.ar]: 'EGY', // Egypt
	};

	return localeToCountry[locale] || null;
};

export const getMainHreflangs = () => ({
	'x-default': `https://${TRIBUNA_COM}/`,
	en: `https://${TRIBUNA_COM}/`,
	it: `https://it.${TRIBUNA_COM}/`,
	es: `https://es.${TRIBUNA_COM}/`,
	fr: `https://fr.${TRIBUNA_COM}/`,
	de: `https://de.${TRIBUNA_COM}/`,
	ar: `https://ar.${TRIBUNA_COM}/`,
	uk: `https://${UA_TRIBUNA_COM}/uk/`,
	ru: `https://${UA_TRIBUNA_COM}/`,
	be: `https://${BY_TRIBUNA_COM}/be/`,
	'ru-BY': `https://${BY_TRIBUNA_COM}/`,
	'ru-RU': `https://${BY_TRIBUNA_COM}/`,
});

export const getSportPath = ({ tag, locale }: { tag: Tag; locale: string }) => {
	return tag?.sport?.id !== SportId.Football
		? tag?.sport?.id === SportId.Bet
			? `/${BETTING_SPORT_URLS?.[locale]}`
			: `/${tag?.sport?.path}`
		: '';
};
